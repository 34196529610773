import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { User } from '../model/user.model';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router, private userService: UserService) { }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: firebase.User) => {
        this.auth.setAuthUser(user);
        if (user) {
          this.auth.setUserDetails(user);
          this.userService.getUser(user.uid).valueChanges().subscribe((userData: User) => {
            this.userService.setCurrentUser(userData);
            if (userData && userData.admin) {
              resolve(true);
            }
            else {
              this.router.navigate(['/login']);
              resolve(false);
            }
          });
        } else {
          this.router.navigate(['/login']);
          resolve(false);
        }
      });
    });
  }
}
