import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { User } from '../model/user.model';
import { Paquete } from '../model/paquete.model';

@Injectable()
export class TipoPaqueteService {


    constructor(
        private firestore: AngularFirestore
    ) {


    }

    getAllPaquetes() {
        return this.firestore.collection('tipo_paquetes', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.orderBy('nombre', 'asc');
            return query
        }).snapshotChanges()
    }

    nuevoPaquete(paquete: Paquete) {
        return this.firestore.collection('tipo_paquetes').add(Object.assign({}, paquete));
    }

    getPaquete(uid: string) {
        return this.firestore.collection<Paquete>('tipo_paquetes').doc(uid).valueChanges();
    }

    updatePaquete(paquete: Paquete) {
        var id = paquete.uid;
        console.log(JSON.stringify(paquete))
        console.log(id)
        return this.firestore.doc('tipo_paquetes/' + id).update(Object.assign({}, paquete));
    }

    eliminarPaquete(uid: string) {
        return this.firestore.collection('tipo_paquetes').doc(uid).delete()
    }
}