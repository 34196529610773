import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserService } from './user.service';
import { User } from '../../shared/model/user.model';
import { AuthService } from './auth.service';

@Injectable()
export class NotificacionService {

  private getIndexUrl: string = environment.twilio.apiUrl + '/notification/';

  constructor(
    private http: HttpClient,
    private auth: AuthService
    ) {
    
  }

  async sendPushNotification(titulo: string, mensaje: string, userUid: string): Promise<any> {
    console.log('notificacion ' + titulo + mensaje + userUid)
    let authData = await this.auth.getAuthUserDetails();
    let tokenId = await authData.getIdToken()
    console.log(authData)
    let headers = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ tokenId
    });
    let body = {
      title: titulo,
      message: mensaje,
      userId: userUid
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.getIndexUrl + 'send', JSON.stringify(body), { headers:headers }).toPromise().then(response => {
        resolve(response);
      }).catch(error => {
        resolve(error)
      });
    });
  }
}