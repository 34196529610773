export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyDaMn8OdFZhxpy6UZB0t7pJNqxLtaKMB_E",
    authDomain: "tenvio-5a2a3.firebaseapp.com",
    databaseURL: "https://tenvio-5a2a3.firebaseio.com",
    projectId: "tenvio-5a2a3",
    storageBucket: "tenvio-5a2a3.appspot.com",
    messagingSenderId: "125577320214",
    appId: "1:125577320214:android:1cb0c4c48a4f3621206237",
    measurementId: "G-Z641Q6MLE1"
  },
  twilio:{
    apiUrl:'https://api.tenvio.com.ar/api',
    api_id:'lkj5as1dFCa0f437905yhf9rHe9ncv50397897A'
  },
  nosis: {
    apiUrl: 'https://ws02.nosis.com/api'
  }
};



