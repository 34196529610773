import { Injectable } from '@angular/core';
import { TemplateConfig } from '../template-config/config.interface';
import { AngularFirestore } from '@angular/fire/firestore';
import { Config } from '../model/config.model';


@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public templateConf: TemplateConfig;

    constructor(
        private firestore: AngularFirestore
    ) {
        this.setConfigValue();
    }


    getAllConfigs() {
        return this.firestore.collection('config', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.orderBy('nombre', 'asc');
            return query
        }).snapshotChanges()
    }

    newConfig(config: Config) {
        return this.firestore.collection('config').add(Object.assign({}, config));
    }

    getConfig(uid: string) {
        return this.firestore.collection<Config>('config').doc(uid).valueChanges();
    }

    updateConfig(config: Config) {
        var id = config.uid;
        console.log(JSON.stringify(config))
        console.log(id)
        return this.firestore.doc('config/' + id).update(Object.assign({}, config));
    }

    eliminarConfig(uid: string) {
        return this.firestore.collection('config').doc(uid).delete()
    }

    updateConfigs(config: any) {
        return this.firestore.doc('config/0BRj1F8zKZmnLrTMhJP9').update(Object.assign({}, config));
    }

    // ejemplo de configuraciones
    //id 0BRj1F8zKZmnLrTMhJP9
    // {
    //     "porcentaje_comision":5,
    //     "factores_precio":{
    //        "rangos_kms":[
    //           {
    //              "final":49,
    //              "inicial":1,
    //              "valor":170
    //           },
    //           {
    //              "valor":180,
    //              "inicial":50,
    //              "final":99
    //           },
    //           {
    //              "inicial":100,
    //              "valor":190,
    //              "final":149
    //           },
    //           {
    //              "final":199,
    //              "valor":200,
    //              "inicial":150
    //           },
    //           {
    //              "final":249,
    //              "valor":210,
    //              "inicial":200
    //           },
    //           {
    //              "valor":220,
    //              "inicial":250,
    //              "final":299
    //           },
    //           {
    //              "valor":230,
    //              "inicial":300,
    //              "final":349
    //           },
    //           {
    //              "inicial":350,
    //              "final":100000,
    //              "valor":240
    //           }
    //        ],
    //        "costo_minimo":80,
    //        "factor_dom_dom":1.4,
    //        "factor_per_dom":1.2
    //     },
    //     "valor_declarado":{
    //        "min":0,
    //        "porcentaje":3,
    //        "min_aplicable":1000,
    //        "max":10000
    //     }
    //  }












    setConfigValue() {
        this.templateConf = {
            layout: {
                variant: 'Light', // options:  Dark, Light & Transparent
                dir: 'ltr', //Options: ltr, rtl
                sidebar: {
                    collapsed: false, //options: true, false
                    size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
                    backgroundColor: "purple-bliss", // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
                    backgroundImage: false, // Options: true, false | Set true to show background image
                    backgroundImageURL: 'assets/img/sidebar-bg/01.jpg'
                }
            }
        }
    }



}
