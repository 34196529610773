import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-footer',
  templateUrl: './index-footer.component.html',
  styleUrls: ['./index-footer.component.scss']
})
export class IndexFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
