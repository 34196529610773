import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-header',
  templateUrl: './index-header.component.html',
  styleUrls: ['./index-header.component.scss']
})
export class IndexHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openUNNOBA(){
      const url = 'https://www.unnoba.edu.ar';
      window.open(url, '_blank');
  }

}
