import { Ciudad } from './../model/ciudad.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { User } from '../model/user.model';

@Injectable()
export class CiudadService {


    constructor(
        private firestore: AngularFirestore
    ) {


    }

    getAllCiudades() {
        return this.firestore.collection('ciudades', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.orderBy('nombre', 'asc');
            return query
        }).snapshotChanges()
    }

    nuevaCiudad(ciudad: Ciudad) {
        return this.firestore.collection('ciudades').add(Object.assign({}, ciudad));
    }

    getCiudad(uid: string) {
        return this.firestore.collection<Ciudad>('ciudades').doc(uid).valueChanges();
    }

    updateCiudad(ciudad: Ciudad) {
        var id = ciudad.uid;
        console.log(JSON.stringify(ciudad))
        console.log(id)
        return this.firestore.doc('ciudades/' + id).update(Object.assign({}, ciudad));
    }

    eliminarCiudad(uid: string) {
        return this.firestore.collection('ciudades').doc(uid).delete()
    }

    nuevaDistancia(data){
        return  this.firestore.collection('distancias').add(data);
    }

    getAlldistancias() {
        return this.firestore.collection('distancias', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.orderBy('origenName', 'asc');
            return query
        }).snapshotChanges()
    }


}