import { Component, OnInit, AfterViewInit, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';


@Component({
    selector: 'index-content-layout',
    templateUrl: './index-layout.component.html',
    styleUrls: ['./index-layout.component.scss']
})

export class IndexLayoutComponent implements OnInit {
  
  constructor() { 
  }

  ngOnInit() {
    
  }



  
  

}
