import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const INDEX_ROUTES: Routes = [
    {
        path: 'index',
        loadChildren: () => import('../../pages/index-layout-page/index-pages.module').then(m => m.IndexPagesModule)
      }
];
