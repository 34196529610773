import { Ciudad } from '../model/ciudad.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { User } from '../model/user.model';
import { Per } from '../model/per.model';

@Injectable()
export class PerService {


    constructor(
        private firestore: AngularFirestore
    ) {


    }

    getAllPers() {
        return this.firestore.collection('pers', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.orderBy('nombre', 'asc');
            return query
        }).snapshotChanges()
    }

    nuevoPer(per: Per) {
        return this.firestore.collection('pers').add(Object.assign({}, per));
    }

    getPer(uid: string) {
        return this.firestore.collection<Ciudad>('pers').doc(uid).valueChanges();
    }

    updatePer(per: Per) {
        var id = per.uid;
        console.log(JSON.stringify(per))
        console.log(id)
        return this.firestore.doc('pers/' + id).update(Object.assign({}, per));
    }

    eliminarPer(uid: string) {
        return this.firestore.collection('pers').doc(uid).delete()
    }


}