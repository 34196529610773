import { Ciudad } from '../model/ciudad.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { User } from '../model/user.model';
import { Per } from '../model/per.model';

@Injectable()
export class InitService {


    constructor(
        private firestore: AngularFirestore
    ) {


    }

// Para inicializar la base de datos
    initConfigs() {


        let config = { "valor_declarado": { "min_aplicable": 0, "porcentaje": 2, "max": 10000, "min": 0 }, "porcentaje_comision": 10, "factores_precio": { "factor_dom_dom": 1.4, "costo_minimo": 70, "rangos_kms": [{ "inicial": 1, "valor": 170, "final": 49 }, { "final": 99, "inicial": 50, "valor": 190 }, { "valor": 210, "final": 149, "inicial": 100 }, { "valor": 225, "final": 199, "inicial": 150 }, { "inicial": 200, "valor": 235, "final": 249 }, { "final": 299, "inicial": 250, "valor": 245 }, { "inicial": 300, "valor": 255, "final": 349 }, { "inicial": 350, "final": 100000, "valor": 260 }], "factor_per_dom": 1.2 } }
        this.firestore.collection('config').doc('0BRj1F8zKZmnLrTMhJP9').set(Object.assign({}, config));

        let sobre = { "ancho": 10, "plural": "Sobres", "activo": true, "uid": "cvnSplbiGNCY4lBslGCv", "rango": 1, "costo": 1, "orden": 1, "alto": 45, "largo": 50, "nombre": "Sobre", "descripcion": "" };
        let caja_chica = { "orden": 2, "ancho": 20, "uid": "lh0B7FiZlwyafOWxcDfa", "rango": 6, "activo": true, "largo": 50, "nombre": "Caja chica", "plural": "Cajas chicas", "alto": 55, "costo": 1.1, "descripcion": " " };
        let caja_grande = { "nombre": "Caja grande", "activo": true, "ancho": 50, "largo": 50, "alto": 50, "descripcion": "", "uid": "YWzAXGIo5ImeOv4IGmxM", "orden": 3, "costo": 1.175, "rango": 30, "plural": "Cajas grandes" };
        let especial = { "costo": 1.4, "nombre": "Especial", "plural": "Especiales", "rango": 10000000, "descripcion": "Por ej: sillones, heladeras, muebles, etc.", "activo": true, "orden": 4, "uid": "eIskmnzLbhlYRef98b85" };


         this.firestore.collection('tipo_paquetes').doc('cvnSplbiGNCY4lBslGCv').set(Object.assign({}, sobre));
         this.firestore.collection('tipo_paquetes').doc('lh0B7FiZlwyafOWxcDfa').set(Object.assign({}, caja_chica));
         this.firestore.collection('tipo_paquetes').doc('YWzAXGIo5ImeOv4IGmxM').set(Object.assign({}, caja_grande));
         this.firestore.collection('tipo_paquetes').doc('eIskmnzLbhlYRef98b85').set(Object.assign({}, especial));




    }






}