import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'environments/environment';

@Injectable()
export class MobbexService {

  private getIndexUrl: string = environment.twilio.apiUrl + '/subscriptions/';

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore
  ) {


  }


  // async generateOrder(userId: string): Promise<any> {
  //   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   let body = {
  //     viajeroUid: userId
  //   }
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.getIndexUrl + 'new-order', JSON.stringify(body), { headers }).toPromise().then(response => {
  //       resolve(response);
  //     }).catch(error => {
  //       resolve(error)
  //     });
  //   });
  // }

  getTarjetasByUser(userUid: string) {
    return this.firestore.collection('subscribers', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('reference', '==', userUid);
      return query
    }).snapshotChanges()

  }

  async execute(subscriberId: string, suscriptionId: string,  valor: number): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let body = {
      subscriptionId: suscriptionId,
      subscriberId: subscriberId,
      valor: valor
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.getIndexUrl + 'capture-order', JSON.stringify(body), { headers }).toPromise().then(response => {
        resolve(response);
      }).catch(error => {
        resolve(error)
      });
    });
  }





}