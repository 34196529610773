import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import * as firebase from 'firebase';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public user: Observable<firebase.User>;
    public userDetails = null;
    public authUser: firebase.User;

    constructor(
        private firebase: AngularFireAuth,
        private router: Router,

    ) {

        this.user = firebase.authState;
        this.user.subscribe(user => {
            if (user) {
                this.userDetails = user;
            } else {
                this.userDetails = null;
            }
        });
    }

    signInWithGoogle() {
        return this.firebase.auth.signInWithPopup(
            new firebase.auth.GoogleAuthProvider()
        );
    }

    isLoggedIn() {
        if (this.userDetails == null) {
            return false;
        } else {
            return true;
        }
    }

    logout() {
        this.firebase.auth
            .signOut()
            .then(res => this.router.navigate(["/home"]));
    }

    setUserDetails(userDetails) {
        this.userDetails = userDetails;
    }

    get currentUserUid() {
        return this.userDetails.uid;
    }

    setAuthUser(user) {
        this.authUser = user;
    }

    getAuthUser() {
        return this.authUser;
    }

    signInWithEmail(email, password) {
        return this.firebase.auth.signInWithEmailAndPassword(email, password)
      }

      async getAuthUserDetails() {
        let user = await this.firebase.auth.currentUser;
        return user;
      }

}