import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    {
        path: '/admin/ciudades', title: 'Ciudades', icon: 'ft-map', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/admin/listar_ciudades', title: 'Listar', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/nueva_ciudad', title: 'Nueva', icon: 'ft-plus-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/distancias', title: 'Distancias', icon: 'ft-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },

    {
        path: '/admin/listar_config', title: 'Config', icon: 'ft-cpu', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/admin/precios', title: 'Precios', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/valor_declarado', title: 'Valor declarado', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/comision', title: 'Comisión', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/admin/factor-riesgo', title: 'Factor riesgo', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {
                path: '/admin/tipos_envios', title: 'Tipos de Envíos', icon: 'ft-inbox', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/admin/listar_tipos_envios', title: 'Listar', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/nuevo_tipo_envio', title: 'Nuevo', icon: 'ft-plus-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            },
        ]
    },


    {
        path: '/admin/listar_envios', title: 'Envíos', icon: 'ft-package', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },


    {
        path: '/admin/pagos', title: 'Pagos', icon: 'ft-award', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },

    {
        path: '', title: 'Reportes', icon: 'ft-flag', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/admin/reporte-viajes', title: 'Viajes', icon: 'fa fa-truck', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },



    /*{
        path: '', title: 'Pers', icon: 'ft-map-pin', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/admin/listar_pers', title: 'Listar', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/nuevo_per', title: 'Nuevo', icon: 'ft-plus-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/admin/pers_users', title: 'Usuarios Per', icon: 'ft-plus-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },*/

    {
        path: '/admin/listar_usuarios', title: 'Usuarios', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },


    {
        path: '/admin/listar_viajes', title: 'Viajes', icon: 'fa fa-truck', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },




];
