import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { User } from '../model/user.model';

@Injectable()
export class UserService {

    currentUser: User;
    currentUserObservable: Subject<User>;

    constructor(
        private firestore: AngularFirestore
    ) {
        this.currentUserObservable = new Subject<User>();

    }

    createUser(user: User) {
        return this.firestore.collection('usuarios').doc(user.uid).set(Object.assign({}, user));
    }

    getUser(uid: string) {
        return this.firestore.collection<User>('usuarios').doc(uid);
    }

    getUsuario(uid: string) {
        return this.firestore.collection<User>('usuarios').doc(uid).valueChanges();
    }

    updateUser(user: User) {
        var id = user.uid;
        return this.firestore.doc('usuarios/' + id).update(Object.assign({}, user));
    }
    

    setCurrentUser(user: User) {
        this.currentUser = user;
        this.currentUserObservable.next(user);
    }

    getCurrentUser(): User {
        return this.currentUser;
    }
    //usuario de sesion 
    getAuthCurrentUser(): any {
        return firebase.auth().currentUser;
    }

    getUserState(uid) {
        return this.firestore.collection<User>('usuarios').doc(uid);
    }

    getAllUsers() {
        //return this.firestore.collection<User>('usuarios').snapshotChanges();
        return this.firestore.collection<User>('usuarios', ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.orderBy('fechaRegistracion', 'desc');
            return query
          }
        ).snapshotChanges();
    }

    updateUserProperty(data:any, uid: string) {
        return this.firestore.doc(`usuarios/${uid}`).update(data);
      }

}